body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
}

a {
  text-decoration: none;
}

.tagStyle {
  padding: 3px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  text-align: center;
  color: #000;
  background-color: #f3f3f3;
}

.sectionTitle {
  margin-bottom: 3px;
  font-weight: 700;
  margin-left: 4px;
  font-size: 12px;
}

.iconBackgroundBg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.fieldLabel {
  font-size: 14px;
  font-weight: bold;
}

.wFull {
  width: 100%;
}

.wHalf {
  width: 50%;
}

.mtLarge {
  margin-top: 30px;
}

.mbLarge {
  margin-bottom: 30px;
}

.mlLarge {
  margin-left: 30px;
}

.mrLarge {
  margin-right: 30px;
}

.mbZero {
  margin-bottom: 0px !important;
}

.mbFive {
  margin-bottom: 5px !important;
}

.customAntInput {
  border-radius: 22px !important;
  height: 50px;
}

.custom-ant-button {
  height: 50px !important;
}

.importantIcon {
  object-fit: contain;
  width: 10px;
  height: 17px;
  margin-right: 3px;
}

.dfaCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.dfsBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dGridCenter {
  display: grid;
  place-items: center;
}

.iconBackgroundBg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.addBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addBoxWhite {
  display: grid;
  place-items: center;
  width: 170px;
  height: 40px;
  color: black;
  background-color: #fff;
}

.activeTopNavLi {
  border-bottom: #3ab44d 3px solid;
}

.ant-table-row {
  background-color: white;
}

.ant-table-row {
  background-color: white;
}

.ant-table-cell {
  background-color: white !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.viewDocs .ant-modal-content {
  width: 520px !important;
  border-radius: 23px !important;
}

.editEmployer .ant-modal-content {
  width: 620px !important;
  border-radius: 10px !important;
}

.ant-upload.ant-upload-drag {
  border-radius: 20px !important;
}

.modalCloseIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
}

.ant-modal-close {
  display: none !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  border-radius: 50%;
  background-color: #3ab44d;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-radio-wrapper-checked span {
  color: #3ab44d !important;
  font-weight: 500;
}

.ant-divider-horizontal {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.ant-picker-input,
.ant-picker.ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}
.ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 10px;
  color: #3ab44d;
}

.ant-select .ant-select-selector {
  border-radius: 10px !important;
}

.ant-select-arrow {
  color: #3ab44d !important;
}

.support .ant-select-selection-placeholder {
  font-size: 14px !important;
}

/* Ant table css table customization starts from here*/

.ant-table-cell {
  font-size: 13px !important;
}

.dashBoardTable .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.inpatientDetail .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
  font-size: 13px !important;
  cursor: auto;
}

.ant-table-cell {
  font-size: 12px !important;
}

.dashBoardTable,
.ant-table-cell {
  cursor: auto;
}

.employeeDetailTable .ant-table-cell {
  cursor: auto;
}

.ant-table-cell {
  cursor: pointer;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
  flex: inherit;
}

/* Ant table css table customization over here*/

.inpatientDetail .ant-input {
  background-color: #f8f8f8;
}

.ant-pagination-options {
  display: none !important;
}

.anticon-delete svg {
  color: red;
  width: 1rem !important;
  height: 1rem !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.ant-btn-primary:disabled {
  background-color: #c6e8c9;
  color: white;
  border: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #f8f8f8 !important;
}

.ant-form-item-explain-error {
  margin-left: 10px;
}

.ant-modal-confirm-btns .ant-btn-default {
  color: black !important;
}

.ant-modal-confirm-btns .ant-btn-default:hover {
  border-color: #3ab44d !important;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: #3ab44d !important;
}

@media print {
  .printButton {
    display: none !important;
  }
}
